import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./card.css";

const UMKMCard = ({ name, image, description, phoneNumber, harga }) => {
  const handleWhatsApp = () => {
    window.location.href = `https://wa.me/${phoneNumber}`;
  };

  return (
    <Card className="umkm-card">
      {" "}
      <Card.Img variant="top" src={image} className="umkm-card-img" />{" "}
      <Card.Body>
        <Card.Title className="umkm-card-title">{name}</Card.Title>{" "}
        <Card.Text className="umkm-card-description">{description}</Card.Text>{" "}
        <Card.Text className="umkm-card-description">{harga}</Card.Text>{" "}
        <button
          onClick={handleWhatsApp}
          className="umkm-card-button"
        >
          {" "}
          <FontAwesomeIcon icon={faWhatsapp} /> <span>Hubungi via WhatsApp</span>
        </button>
      </Card.Body>
    </Card>
  );
};

export default UMKMCard;
