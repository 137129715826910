import React from "react";
import BannerBackground from "../../assets/home-banner-background.png";
import BannerImage from "../../assets/produk-umkm.png";
import { FiArrowRight } from "react-icons/fi";
import "./home.css";
import { Helmet } from "react-helmet";

const Home = () => {
  const handleUmkmk = () => {
    window.location.href = "/umkm";
  };
  return (
    <div className="home-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Srikandi Cipta Bahari - UMKM Semarang</title>
        <meta
          name="description"
          content="Srikandi Cipta Bahari adalah UMKM di Semarang yang menawarkan berbagai produk lokal berkualitas."
        />
        <link rel="canonical" href="https://www.umkm-srikandi.com/" />
      </Helmet>
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">UMKM SRIKANDI CIPTA BAHARI</h1>
          <p className="primary-text">Usaha Mikro Kecil Menengah</p>
          <button className="secondary-button" onClick={handleUmkmk}>
            Lihat Selengkapnya <FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
