import React, { useState, useEffect } from "react";
import "./ProductPage.css";
import ProductPage from "../../component/Card/card";

const Api_Url = process.env.REACT_APP_API_URL;

const ProdukPage = () => {
  const [productList, setProductList] = useState([]);
  const [filteredProductsList, setFilteredProductList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProductList = async () => {
      try {
        const response = await fetch(
          `${Api_Url}/api/products?page=${currentPage}&size=${itemsPerPage}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch Product data");
        }
        const data = await response.json();
        setProductList(data.content);
        setFilteredProductList(data.content);
        setTotalPages(data.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching Product data:", error);
        setIsLoading(false);
      }
    };

    fetchProductList();
  }, [currentPage, itemsPerPage]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const filteredProduct = productList.filter(
      (product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProductList(filteredProduct);
  }, [searchQuery, productList]);

  const formatHarga = (harga) => {
    return harga.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 0; i < totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className="filter">
        <form onSubmit={(e) => e.preventDefault()}>
          <label htmlFor="searchInput">Search:</label>
          <input
            type="text"
            id="searchInput"
            placeholder="Type here..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </form>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={`product-page ${
            filteredProductsList.length === 1 ? "single-data" : ""
          }`}
        >
          {filteredProductsList.length > 0 ? (
            filteredProductsList.map((product, index) => (
              <ProductPage
                key={index}
                name={product.name}
                image={`data:image/jpeg;base64,${product.image}`}
                description={product.description}
                harga={`Rp. ${formatHarga(product.harga)}`}
                phoneNumber={product.phoneNumber}
              />
            ))
          ) : (
            <p>Nama Produk Tidak Ditemukan.</p>
          )}
        </div>
      )}
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${number === currentPage ? "active" : ""}`}
          >
            <button onClick={() => paginate(number)} className="page-link">
              {number + 1}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ProdukPage;
