import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./component/Navbar/navbar";
import Home from "./component/Home/home";
import UMKMPage from "./page/Umkm/UmkmPage";
import ProdukPage from "./page/Produk/ProductPage";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/umkm" element={<UMKMPage />} />
          <Route path="/katalog" element={<ProdukPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
