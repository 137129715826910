import { Container, Nav, Navbar } from "react-bootstrap";
import NavbarImage from "../../assets/logo.webp";
import "./navbar.css";

function NavbarComponent() {
  return (
    <Navbar bg="white" expand="lg">
      <Container>
        <img src={NavbarImage} alt="" className="navbar-image" />
        <Navbar.Brand href="/" className="brand-navbar">
          Srikandi Cipta Bahari
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/" className="brand-menu">
              Home
            </Nav.Link>
            <Nav.Link href="/umkm" className="brand-menu">
              Umkm
            </Nav.Link>
            <Nav.Link href="/katalog" className="brand-menu">
              Katalog
            </Nav.Link>
            {/* <Nav.Link href="tentang-kami" className="brand-menu">Tentang Kami</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
