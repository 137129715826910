import React, { useState, useEffect } from "react";
import "./UmkmPage.css";
import UMKMCard from "../../component/Card/card";

const Api_Url = process.env.REACT_APP_API_URL;

const UMKMPage = () => {
  const [umkmList, setUmkmList] = useState([]);
  const [filteredUmkmList, setFilteredUmkmList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUmkmList = async () => {
      try {
        const response = await fetch(
          `${Api_Url}/api/umkms?page=${currentPage}&size=${itemsPerPage}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        );
        const data = await response.json();
        setUmkmList(data.content);
        setFilteredUmkmList(data.content);
        setTotalPages(data.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching UMKM data:", error);
        setIsLoading(false);
      }
    };

    fetchUmkmList();
  }, [currentPage, itemsPerPage]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const filteredUmkm = umkmList.filter(
      (umkm) =>
        umkm.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        umkm.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUmkmList(filteredUmkm);
  }, [searchQuery, umkmList]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 0; i < totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className="filter">
        <form onSubmit={(e) => e.preventDefault()}>
          <label htmlFor="searchInput">Search:</label>
          <input
            type="text"
            id="searchInput"
            placeholder="Type here..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </form>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={`umkm-page ${
            filteredUmkmList.length === 1 ? "single-data" : ""
          }`}
        >
          {filteredUmkmList.length > 0 ? (
            filteredUmkmList.map((umkm, index) => (
              <UMKMCard
                key={index}
                name={umkm.name}
                image={`data:image/jpeg;base64,${umkm.image}`}
                description={umkm.description}
                phoneNumber={umkm.phoneNumber}
              />
            ))
          ) : (
            <p>Nama Komunitas UMKM Tidak Ditemukan.</p>
          )}
        </div>
      )}
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${number === currentPage ? "active" : ""}`}
          >
            <button onClick={() => paginate(number)} className="page-link">
              {number + 1}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default UMKMPage;
